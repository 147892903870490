import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Footer from "../../Base/Footer";
import Header from "../../Base/Header";
import axios from "axios";
import { serverUrl } from "../../../consts.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  B,
  clock,
  htmltag,
  link,
  newBuild,
  redditStroke,
  T,
  closeIcon,
  tickFilled,
} from "../../Base/SVG";
import KeyboardBuilderItem from "./KeyboardBuilderItem";
import { KeyboardBuilderModul } from "./KeyboardBuilderModul";

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function closeHistory(){
  document.getElementById("buildHistory").style.display = "none";
}

function openHistory(){
  document.getElementById("buildHistory").style.display = "block";
}

export default function KeyboardBuilder() {
  const params = useParams();
  let navigate = useNavigate();
  const [loadedBuildData, setLoadedBuildData] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const [buildData, setBuildData] = useState([]);
  const [partsList, setPartsList] = useState([]);
  const[sortedPartsList, setSortedPartsList] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [buildHash, setBuildHash] = useState(" ");

  if((params.buildHash === undefined || params.buildHash === "") && !(localStorage.getItem("buildHash") !== null)) {
    params.buildHash = " ";
    setBuildHash(" ");
  } else if(params.buildHash === undefined) {
    params.buildHash = decodeURIComponent(localStorage.getItem("buildHash"));
    setBuildHash(decodeURIComponent(localStorage.getItem("buildHash")));

  }

  useEffect(() => { 
    setBuildHash(params.buildHash);
  }, [params.buildHash]);

  function updateBuildHash(newHash) {
    // Update buildHash in local storage
    params.buildHash = newHash;

    setBuildHash(newHash);
  }

  function updateBuildData(newBuild) {
    setBuildData(newBuild);
  }

  useEffect(() => {
    if  (params.buildHash !== undefined && params.buildHash !== "" || params.buildHash !== null) {
      
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == undefined || buildHistory == null || buildHistory == "undefined") {
        buildHistory = {};
      }else{
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      buildHistory[new Date()] = ["Loaded new Build ", params.buildHash];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      //localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

      navigate('../../build/'+ params.buildHash);
    }
  }, [params.buildHash]);

  useEffect(() => {

    // Check if data for this build is cached and if so use that instead of fetching it from the server
    let cachedBuilddata = localStorage.getItem("buildData_" + params.buildHash);
    if(cachedBuilddata !== "" && cachedBuilddata !== undefined && cachedBuilddata !== null && cachedBuilddata !== "undefined"){
      setBuildData(JSON.parse(cachedBuilddata));
      setLoadedBuildData(true);
      return;
    }

    axios.get(serverUrl+'build/'+params.buildHash)
    .then(function (response) {
      setBuildData(response.data[0]);
      setLoadedBuildData(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoadedBuildData(true);
    });

  }, [params.buildHash]);

  useEffect(() => {
    if(loadedBuildData){
      setPartsList([]);
      setLoaded(false);
  
      // Loop through keys and value of buildData
      let i = 0;
      if(buildData != undefined){
        for (const [key, value] of Object.entries(buildData)) {
          // Get the data for each part except for the id and hash key
          
          if(key !== "id" && key !== "hash" && !key.includes("QNT")) {
    
            if(value !== null){

              // Check if this part data is cached in the browser
              let cachedPartData = localStorage.getItem("partData_" + value);
              if(cachedPartData !== "" && cachedPartData !== undefined && cachedPartData !== null && cachedPartData !== "undefined"){
                
                // Append to partsList
                setPartsList(partsList => [...partsList, JSON.parse(decodeURIComponent(cachedPartData))]);
                
                i++;
              } else {
                axios.get(serverUrl+'product/id/'+value)
                .then(function (response) {

                  if(response.data[0] == undefined){
                    console.log("ERROR: Part data not found for part with ID: " + value);
                    return;
                  }

                 // Append to partsList
                  setPartsList(partsList => [...partsList, response.data[0]]);
                  
                  // Cache this parts data in the browser
                  localStorage.setItem("partData_" + value, encodeURIComponent(JSON.stringify(response.data[0])), 7);
  
                })
                .catch(function (error) {
                  console.log(error);
                });
              }
            } else {
              let value = {
                id: {i},
                data: "0",
                type: key.replace('ProductID', '').replace('barebones', 'keyboard-barebones').replace('keycaps', 'key-caps'),
                image: "",
                name: "",
                price: "",
                group: "",
                image2: "",
                qnt: 1
              };
    
              setPartsList(partsList => [...partsList, value]);
    
            }
          }
          i++;

          // Set loaded to true once last part is loaded
          if(i >= Object.entries(buildData).length){
            setLoaded(true);
          }
    
        }
      } else {
        // No build data found so set loaded to true
        setLoaded(true);
      }


      // Once loaded cache the build data in the browser for this build hash
      if(loadedBuildData){
        localStorage.setItem("buildData_" + params.buildHash, JSON.stringify(buildData), 1);
      }

    }

  }, [buildData]);

  useEffect(() => {
    sortPartsList();
  }, [partsList]);


  function sortPartsList() {
    // Order partsList by type in following order: Barebones, Cases, PCBs, Switches, Keycaps, Cables, Stabilizers, Accessories, Lubes, Tools
    let orderedPartsList = [];
    let types = ["keyboard-barebones", "cases", "pcbs", "switches", "key-caps", "Cable", "stabilizers", "accessories", "lubes", "tools"];

    types.forEach((type) => {
      partsList.forEach((part) => {
          if(part.type == type || part['type'] == type){
            orderedPartsList.push(part);
          }
      });

    }
    );

    setSortedPartsList(orderedPartsList);
  }

  useEffect(() => {
    let cachedBuilddata = localStorage.getItem("buildData_" + params.buildHash);
    if(cachedBuilddata == "" || cachedBuilddata == null) {
      cachedBuilddata = {};
    } else {
      cachedBuilddata = JSON.parse(decodeURIComponent(cachedBuilddata));
    }

    // Calculate total price of build by looping through partsList
    let totalPrice = 0;
    partsList.forEach((part) => {
      totalPrice += Number(part.price) * Number(cachedBuilddata[part.type.replace('keyboard-', '').replace('key-caps', 'keycaps')+"QNT"]);
    }
    );
    setTotalPrice(totalPrice.toFixed(2));
  }, [partsList, buildHash]);

  function copyBuildURL() {
    navigator.clipboard.writeText(
      "https://keyboardpartpicker.io/build/" + buildHash
    );

    toast('📋 Copied to Clipboard!', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  function startNewBuild(){
    // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
    let buildHistory = localStorage.getItem("buildHistory");
    if(buildHistory == "" || buildHistory == null) {
      buildHistory = {};
    } else {
      buildHistory = JSON.parse(decodeURIComponent(buildHistory));
    }

    localStorage.clear(); // Clear everything except the build history
    localStorage.setItem("buildHistory", buildHistory);

    params.buildHash = " ";
    setBuildHash(" ");

    localStorage.setItem("buildHash", " ", 365);

    navigate('../../build/'+ params.buildHash);
  }

  localStorage.setItem("buildHash", params.buildHash, 365);

  // Get the build history for this build from the cookie if it exists
  let buildHistory = localStorage.getItem("buildHistory");
  if(buildHistory !== "" && buildHistory !== undefined && buildHistory !== null && buildHistory !== "undefined"){
    buildHistory = JSON.parse(decodeURIComponent(buildHistory));
  } else {
    buildHistory = {};
  }

  // Convert buildHistory object to array
  buildHistory = Object.keys(buildHistory).map(function(key) {
    let temp = buildHistory[key];
    temp.push(new Date(key).toLocaleDateString() + " " + new Date(key).toLocaleTimeString())
    return temp;
  });

  // Sort buildHistory array by date
  buildHistory.sort(function(a, b) {
    return new Date(b[2]) - new Date(a[2]);
  });

  return (
    <>
      <Header />
      <main>
        <div className="builder">
          <div className="auto__container">
            <div className="builder__inner">
              <h2 className="sm">keyboard builder</h2>
              <div className="breadcrumb">
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>Parts</li>
                </ul>
              </div>
              <div id="buildHistory" className="historyPopup builder__tools builder__tools">
                <div className="tableDiv">
                  <div className="headText">
                    {clock} <h3>Part List History</h3>
                  </div>
                  <button onClick={closeHistory} type="button" className="historyPopup__close">{closeIcon}</button>
                  
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>New Build List</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(buildHistory).length > 0 ? buildHistory.map((history, index) => (
                        <tr key={index}>
                          <td>{history[0]}</td>
                          <td><a href={"../build/"+history[1]}>/build/{history[1]}</a></td>
                          <td>{history[2]}</td>
                        </tr>
                      )) : <tr><td colSpan="3">No history found</td></tr>}
                    </tbody>

                  </table>
                </div>
              </div>
              <div className="builder__tools">
                <a onClick={copyBuildURL} href="#" className="builder__tools-link">
                  {link} {"https://keyboardpartpicker.io/build/" + buildHash}
                </a>
                <ToastContainer />
                <div className="builder__tools-others">
                  <div className="builder__tools-markup">
                    <p className="uniq">Markup:</p>
                    <div className="builder__tools-markup-btns">
                      <button
                        type="button"
                        className="builder__tools-markup-btn"
                      >
                        {redditStroke}
                      </button>
                      <button
                        type="button"
                        className="builder__tools-markup-btn"
                      >
                        {htmltag}
                      </button>
                      <button
                        type="button"
                        className="builder__tools-markup-btn"
                      >
                        {T}
                      </button>
                      <button
                        type="button"
                        className="builder__tools-markup-btn"
                      >
                        {B}
                      </button>
                    </div>
                  </div>
                  <button onClick={openHistory} type="button" className="builder__tools-history">
                    {clock} History
                  </button>
                  <button onClick={startNewBuild} type="button" className="builder__tools-newbuild">
                    {newBuild} New Build
                  </button>
                </div>
              </div>
              <div className="builder__infobox">
                {tickFilled} Compatibility: No issues or incompatibilities
                found.
              </div>
              <div className="builder__table">

              {
                  // Check if page is loading
                  !loaded && (
                  <div style={{margin: '10rem', justifyContent: 'center'}} className="switchList__items">
                    <div className="switchList__items-item ">
                      <div className="switchList__items-item-text">
                        <h5>Loading...</h5>
                        <p>
                        We are currently loading the Build for you...
                        </p>
                      </div>
                    </div>
                    </div>
                  ) 
                }
                {
                  // Check if no build was found
                  loaded && partsList.length === 0 && (
                    <div style={{margin: '5rem', justifyContent: 'center'}} className="switchList__items">
                      <div className="switchList__items-item ">
                        <div className="switchList__items-item-text">
                          <h5>Build not found</h5>
                          <p>
                            We couldn't find any Build with the given Build Hash.
                          </p>
                        </div>
                      </div>
                      </div>
                  ) 
                }
              {
                // Show build table if page is loaded
                loaded && partsList.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Parts</th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Group buy</th>
                      <th>Quantity</th>
                      <th>Where to buy</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                        sortedPartsList.map((item, index) => {
                          return <KeyboardBuilderItem updateBuildDataFunction={updateBuildData} updateBuildHashFunction={updateBuildHash} key={index} {...item} />;
                        })
                  }
                  </tbody>
                </table>
                )}
              </div>
              <div className="builder__total">
                <div className="builder__total-inner">
                  <div className="builder__total-inner-text">
                    Grand Total: <span>${totalPrice}</span>
                  </div>
                  {/*<a href="#" className="button buy">
                    buy from amazon
                  </a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
