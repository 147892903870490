import React from "react";
import { tickFilled } from "../../../../../Base/SVG";

export default function ReviewItem(props) {
  return (
    <div className="tabs__review-comment-item">
      <h5 className="big">
        {props.name} {tickFilled}
      </h5>
      <p className="xsm">{props.date}</p>
      <div className="rate">
        <div className="rate__stars">
          <img src={process.env.PUBLIC_URL + "/images/stars.png"} alt="stars" />
        </div>
        <div className={"rate__filled " + props.rate}>
          <img
            src={process.env.PUBLIC_URL + "/images/stars-filled.png"}
            alt="stars"
          />
        </div>
      </div>
      <p>{props.text}</p>
    </div>
  );
}
