import { Route, Routes } from "react-router-dom";
import Index from "./Components/Pages/Index/Index";
import KeyboardBuilder from "./Components/Pages/KeyboardBuilder/KeyboardBuilder";
import SwitchList from "./Components/Pages/SwitchList/SwitchList";
import SwitchListNotFound from "./Components/Pages/SwitchList/SwitchListNotFound";
import SwitchMain from "./Components/Pages/SwitchMain/SwitchMain";
import TermsConditions from "./Components/Pages/TermsConditions/Index";
import Privacy from "./Components/Pages/Privacy/Index";
import AboutUs from "./Components/Pages/AboutUs/Index";
import ContactUs from "./Components/Pages/ContactUs/Index";

function App() {
  return (
    <Routes>
      <Route path="*" index element={<Index />} />
      <Route path="/build/:buildHash" index element={<KeyboardBuilder />} />
      <Route path="/build/" index element={<KeyboardBuilder />} />
      <Route path="/products/:product" index element={<SwitchList />} />
      <Route path="/product/:productName" index element={<SwitchMain />} />
      <Route path="/not_found" index element={<SwitchListNotFound />} />
      <Route path="/conditions" index element={<TermsConditions />} />
      <Route path="/privacy" index element={<Privacy />} />
      <Route path="/about" index element={<AboutUs />} />
      <Route path="/contact" index element={<ContactUs />} />
    </Routes>
  );
}

export default App;
