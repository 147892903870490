import React, { useState } from "react";
import Review from "./Review/Review";
import Specification from "./Specification";

export default function Tabs(props) {
  const [tab, setTab] = useState("spec");
  return (
    <div className="tabs">
      <div className="auto__container">
        <div className="tabs__inner">
          <div className="tabs__title">
            <div
              className={"tabs__title-tab " + (tab === "spec" ? "active" : "")}
              onClick={() => {
                setTab("spec");
              }}
            >
              <h5 className="big">Specifications</h5>
            </div>
            <div
              className={
                "tabs__title-tab " + (tab === "review" ? "active" : "")
              }
              onClick={() => {
                setTab("review");
              }}
            >
              {/*<h5 className="big">Review</h5>*/}
            </div>
          </div>
          {tab === "spec" && <Specification {...props} />}
          {tab === "review" && <Review />}
        </div>
      </div>
    </div>
  );
}
