import React from "react";
import CompCatItem from "./CompCatItem";
import { CompCatModul } from "./CompCatModul";

export default function CompCat() {
  return (
    <div className="compcat">
      <div className="auto__container">
        <div className="compcat__inner">
          <h2>Component categories</h2>
          <p className="sm">View our top components by their categories</p>
          <div className="compcat__inner-items">
            {CompCatModul.map((item, index) => {
              return <CompCatItem key={index} {...item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
