export const ReviewModul = [
  {
    id: "1",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star5",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "2",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star4",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "3",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star3",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "4",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star2",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "5",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star1",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "6",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star5",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
  {
    id: "7",
    name: "Nick John",
    date: "7/25/2022",
    rate: "star5",
    text: "Lorem ipsum dolor sit amet consectetur. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem. Eleifend gravida tellus auctor amet magna. Metus  enim proin donec facilisi. Ultricies ornare quis lorem.",
  },
];
