export const SwitchMainModul = [
  {
    id: "1",
    merchant: "/images/amazon.png",
    base: "$45",
    shipping: "+Free s/h",
    availability: "In stock",
    groupBuy: "1",
    total: "$45",
  },
  {
    id: "2",
    merchant: "/images/amazon.png",
    base: "$45",
    shipping: "+Free s/h",
    availability: "In stock",
    groupBuy: "1",
    total: "$45",
  },
  {
    id: "3",
    merchant: "/images/amazon.png",
    base: "$45",
    shipping: "+Free s/h",
    availability: "In stock",
    groupBuy: "0",
    total: "$45",
  },
  {
    id: "4",
    merchant: "/images/amazon.png",
    base: "$45",
    shipping: "+Free s/h",
    availability: "In stock",
    groupBuy: "0",
    total: "$45",
  },
  {
    id: "5",
    merchant: "/images/amazon.png",
    base: "$45",
    shipping: "+Free s/h",
    availability: "In stock",
    groupBuy: "1",
    total: "$45",
  },
];
