import React from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import IntroItem from "./IntroItem";
import { IntroModul } from "./IntroModul";
import { NavLink } from "react-router-dom";

export default function Intro() {
  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
  };

  const handleClickScroll = () => {
    const element = document.getElementById('hotBuilds');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="intro">
      <div className="auto__container">
        <div className="intro__inner">
          <h1>Ready to Build your first custom keyboard?</h1>
          <p>
          kepp [kæp], also known as <b>Ke</b>yboard <b>P</b>art <b>P</b>icker, is a tool that allows you to customize your Mechanical Keyboard Build by filtering and selecting parts that are automatically checked for compatibility.
          </p>
          <div className="introSlider">
            <Slider {...settings}>
              {IntroModul.map((item, index) => {
                return <IntroItem key={index} {...item} />;
              })}
            </Slider>
          </div>
          <div className="button__row">
            <NavLink
              to="/build"
              type="button"
              className="button primary"
            >
              Build now
            </NavLink>
            {/*<button onClick={handleClickScroll} type="button" className="button secondary">
              pre-built keyboard
            </button>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
