import React from "react";

export default function IntroItem(props) {
  return (
    <div className="introSlider__item">
      <div className="introSlider__item-image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </div>
    </div>
  );
}
