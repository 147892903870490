import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../consts.js";

export default function SwitchListItem(props) {
  let navigate = useNavigate();
  let itemProperties = [];

  if(props.type == 'switches'){
    itemProperties.push(props.switchType);
    itemProperties.push(props.soundRange.replace('0', 'Quiet').replace('1', 'Moderate Noise').replace('2', 'Loud'));
    itemProperties.push(props.soundType);
  }

  if(props.type == 'key-caps'){
    itemProperties.push(props.keycapProfile);
  }

  if (props.type !== 'tools'){
    itemProperties.push(props.stemType);
  }

  itemProperties.push(props.material);
  if(props.type !== 'tools' && props.type !== 'stabilizers' && props.type !== 'lubes'){
    itemProperties.push(props.layout.replace(' / 1800 Compact', ''));
    itemProperties.push(props.keycapType);
    itemProperties.push(props.lighting);
  }

  if(props.type !== 'switches' && props.type !== 'lubes'){
    // Only show these for non switches if there are really no other properties to show
    itemProperties.push(props.switchType);
    itemProperties.push(props.soundRange.replace('0', 'Quiet').replace('1', 'Moderate Noise').replace('2', 'Loud'));
    itemProperties.push(props.soundType);
  }

  // Remove empty or 'Unknown' properties
  itemProperties = itemProperties.filter(function (el) {
    return el != null && el != 'Unknown' && el != 'None';
  });

  // Only show the first 3 properties
  itemProperties = itemProperties.slice(0, 3);

  // Order properties by text length
  itemProperties.sort(function(a, b) {
    return a.length - b.length;
  });

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function addComponent(partId, partType, partName) {
    let buildHash = localStorage.getItem("buildHash");

    if(buildHash === "") {
      buildHash = " ";
    }

    axios.get(serverUrl+'build/'+buildHash+'/add', {
      params: {
        partID: partId,
        partType: partType
      }
    })
    .then(function (response) {
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == null) {
        buildHistory = {};
      }else{
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      buildHistory[new Date()] = ["Added "+partName+" to build", response.data['newBuildHash']];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

      navigate('../../build/'+response.data['newBuildHash'])
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }

  return (
    <div className={props.uiDisplayType+"__item"}>
      <div className={props.uiDisplayType+"__item-inner"}>
        <a href={"/product/"+props.name.replace(/-/g, "-MINUS-").replace(/%/g, "-PERCENT-").replace(/\+/g, "-PLUS-").replace(/\//g, "-SLASH-").replace(/ /g, "-")} className={props.uiDisplayType+"__item-image"}>
          <img src={process.env.PUBLIC_URL + props.imageURL} alt={props.name} loading="lazy" />
        </a>
        {props.sponsoredTil !== null && (
          <a href="#" className={props.uiDisplayType+"__item-sponsored"}>
            Sponsored
          </a>
        )}
        <div className={props.uiDisplayType+"__item-colors"}>
        {props.colors !== null && props.colors.split(",").map((color, index) => {  
          return <a href="#" className={props.uiDisplayType+"__item-color "+color.toLowerCase()}></a>
          })}
        </div>
      </div>
      {props.inStock === 1 ? (
        <button onClick={() => addComponent(props.id, props.type, props.name)} className="button button primary large">
          Add component
        </button>
      ) : (
        <NavLink
          to="/build"
          className="button button primary large stock"
        >
          Out of stock
        </NavLink>
      )}
      {(itemProperties.length > 0) && (
        <div className={props.uiDisplayType+"__item-tags"}>
          {itemProperties.map((itemProperty, index) => {
            return <div className={props.uiDisplayType+"__item-tag"}>{itemProperty}</div>
          })}
        </div>
      )}
      <NavLink to={"/product/"+props.name.replace(/-/g, "-MINUS-").replace(/%/g, "-PERCENT-").replace(/\+/g, "-PLUS-").replace(/\//g, "-SLASH-").replace(/ /g, "-")} className={props.uiDisplayType+"__item-link"}>
        <h5>{props.name}</h5>
      </NavLink>
      <h4>{props.price}$</h4>
    </div>
  );
}
