import React from "react";

export default function Specification(props) {
  
  const specsToShow = {
    "key-caps": ['stemType', 'amountIncluded', 'material', 'keycapProfile', 'keycapType', 'includesNumpad', 'lighting', 'color'],
    "keyboard-barebones": ['layout', 'material', 'connectivity', 'hotSwappable', 'lighting', 'knob', 'color'],
    "switches": ['switchType', 'stemType', 'soundRange', 'soundType', 'activationForce', 'lubed', 'lighting', 'color'],
    "stabilizers": ['material', 'amountIncluded', 'color'],
    "keyboards": ['layout', 'material', 'connectivity', 'hotSwappable', 'lighting', 'knob', 'color'],
    "tools": ['material', 'amountIncluded', 'color'],
    "lubes": ['material', 'amountIncluded', 'color'],
    "Unknown": ['material', 'color'],
  }

  function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
  }

  function roundDown(num, precision) {
    precision = Math.pow(10, precision)
    return Math.floor(num * precision) / precision
  }
  

  let i = 0;
  let loadedFirstCol = false;

  return (
    <div className="tabs__spec">
      <div className="tabs__spec-title rounded">
        <h5 className="big fw500">Tech specs</h5>
      </div>
      <div className="tabs__spec-tech">
        <div className="tabs__spec-tech-col">
          <h5 className="big">key specs</h5>
          <div className="tabs__spec-tech-items">
          {
            /* Loop through all keys and values of the props Object */
            Object.keys(props).map((key, index) => {
              if(i < roundUp(specsToShow[props.type].length / 2, 0)-1 && !loadedFirstCol){
                if (specsToShow[props.type].indexOf(key) > -1 && props[key] !== "" && props[key] !== 0 && props[key] !== 1 && props[key] !== "Unknown") {
                  i += 1;

                  let value = props[key];
                  if(key == "soundRange"){
                    value = props[key].replace('0', 'Quiet').replace('1', 'Moderate Noise').replace('2', 'Loud')
                  }

                  return (
                    <div className="tabs__spec-tech-item">
                      <h5 className="big fw500">{key}:</h5>
                      <p>{value + (key == "activationForce" ? " g" : "") }</p>
                    </div>
                  );
                }
              } else {
                i = 0;
                loadedFirstCol = true;
              }
            })
          }
          </div>
        </div>

        <div className="tabs__spec-tech-col">
          <h5 className="big">key specs</h5>
          <div className="tabs__spec-tech-items">
          {
            /* Loop through all keys and values of the props Object */
            Object.keys(props).map((key, index) => {
              console.log(i)
              //console.log(roundUp(specsToShow[props.type].length / 2, 0))

              if (specsToShow[props.type].indexOf(key) > -1 && props[key] !== "" && props[key] !== 0 && props[key] !== 1 && props[key] !== "Unknown") {
                i += 1;

                if(i >= roundUp(specsToShow[props.type].length / 2, 0)){
                  return (
                    <div className="tabs__spec-tech-item">
                      <h5 className="big fw500">{key}:</h5>
                      <p>{props[key] + (key == "activationForce" ? " g" : "") }</p>
                    </div>
                  );
                }
              }
            })
          }
          </div>
        </div>
        </div>
      <div className="tabs__spec-title">
        <h5 className="big fw500">Overview</h5>
      </div>
      <div className="tabs__spec-overview">
        {/*<div className="tabs__spec-overview-image">
          <img src={props.images.split(", ")[0]} alt="" />
        </div>
        <h5 className="big">Description</h5>*/}
        <p dangerouslySetInnerHTML={{__html: props.description.split("                1")[0].split(" |  / ").pop().replace(/\n\n\n/g, '\n').replace(/\n\n\n/g, '\n').replace(/\n/g, '</br>').trim()}}>
        </p>
      </div>
    </div>
  );
}
