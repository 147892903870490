import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "../../Base/Header";
import RangeSlider, { RangeSliderInput } from "../../Base/RangeSlider";
import { closeIcon, filterIcon, search } from "../../Base/SVG";
import SwitchListItem from "./SwitchListItem";
import axios from "axios";
import { serverUrl } from "../../../consts.js";


export default function SwitchList() {
  const params = useParams();
  const [loaded, setLoaded] = useState(false);

  const priceInpVal = (value) => value === "" || (value.toString().length <= 6 && value <= 1000 && value >= 0);
  const amountInpVal = (value) => value === "" || (value.toString().length <= 6 && value <= 250 && value >= 0);
  const [range1, setRange1] = useState(0);
  const [range2, setRange2] = useState(1000);
  const [range1Amount, setRange1Amount] = useState("0");
  const [range2Amount, setRange2Amount] = useState("250");
  const [manufacturerSearchTerm, setManufacturerSearchTerm] = useState("");
  const soundInp = useRef(null);
  const activationInp = useRef(null);
  const [sound, setSound] = useState("0");
  const [activation, setActivation] = useState("1");
  const [filter, setFilter] = useState(false);
  
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const handleManufacturerCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedManufacturers([...selectedManufacturers, e.target.value]);
    } else {
      setSelectedManufacturers(selectedManufacturers.filter((item) => item !== e.target.value));
    }

  };

  useEffect(() => {
    // Reset all filters when product changes
    document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

    setSelectedStems([]);
    setSelectedTypes([]);
    setSelectedSoundTypes([]);
    setSelectedkeycapType([]);
    setSelectedkeycapProfile([]);
    setSelectedMaterials([]);
    setSelectedincludesNumpad([]);
    setselectedavailability([]);
    setselectedlayout([]);
    setselectedconnectivity([]);
    setselectedhotSwappable([]);
    setselectedlighting([]);
    setselectedknob([]);
    setSelectedColors([]);
    setSound("0");
    setActivation("0");

  }, [params.product]);


  useEffect(() => {
    axios.get(serverUrl+params.product+'/vendors', {
      params: {
        name: manufacturerSearchTerm,
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setManufacturerList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [manufacturerSearchTerm])

  const [stemList, setStemList] = useState([]);
  const [selectedStems, setSelectedStems] = useState([]);
  const handleStemCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedStems([...selectedStems, e.target.value]);
    } else {
      setSelectedStems(selectedStems.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/stems', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setStemList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [typeList, setTypeList] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const handleTypeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedTypes([...selectedTypes, e.target.value]);
    } else {
      setSelectedTypes(selectedTypes.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/types', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setTypeList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [soundTypeList, setSoundTypeList] = useState([]);
  const [selectedSoundTypes, setSelectedSoundTypes] = useState([]);
  const handleSoundTypeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedSoundTypes([...selectedSoundTypes, e.target.value]);
    } else {
      setSelectedSoundTypes(selectedSoundTypes.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/soundTypes', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setSoundTypeList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [keycapTypeList, setkeycapTypeList] = useState([]);
  const [selectedkeycapTypes, setSelectedkeycapType] = useState([]);
  const handlekeycapTypeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedkeycapType([...selectedkeycapTypes, e.target.value]);
    } else {
      setSelectedkeycapType(selectedkeycapTypes.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/keycapType', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setkeycapTypeList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [keycapProfileList, setkeycapProfileList] = useState([]);
  const [selectedkeycapProfile, setSelectedkeycapProfile] = useState([]);
  const handlekeycapProfileCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedkeycapProfile([...selectedkeycapProfile, e.target.value]);
    } else {
      setSelectedkeycapProfile(selectedkeycapProfile.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/keycapProfile', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setkeycapProfileList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [materialList, setMaterialList] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const handleMaterialCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedMaterials([...selectedMaterials, e.target.value]);
    } else {
      setSelectedMaterials(selectedMaterials.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/materials', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setMaterialList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [includesNumpadList, setincludesNumpadList] = useState([]);
  const [selectedincludesNumpad, setSelectedincludesNumpad] = useState([]);
  const handleincludesNumpadCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedincludesNumpad([...selectedincludesNumpad, e.target.value]);
    } else {
      setSelectedincludesNumpad(selectedincludesNumpad.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/includesNumpad', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setincludesNumpadList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [availabilityList, setavailabilityList] = useState([]);
  const [selecteavailability, setselectedavailability] = useState([]);
  const handleAvailabilityCheckbox = (e) => {
    if (e.target.checked) {
      setselectedavailability([...selecteavailability, e.target.value]);
    } else {
      setselectedavailability(selecteavailability.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/availability', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setavailabilityList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [layoutList, setlayoutList] = useState([]);
  const [selectedlayout, setselectedlayout] = useState([]);
  const handlelayoutCheckbox = (e) => {
    if (e.target.checked) {
      setselectedlayout([...selectedlayout, e.target.value]);
    } else {
      setselectedlayout(selectedlayout.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/layout', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setlayoutList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [connectivityList, setconnectivityList] = useState([]);
  const [selectedconnectivity, setselectedconnectivity] = useState([]);
  const handleconnectivityCheckbox = (e) => {
    if (e.target.checked) {
      setselectedconnectivity([...selectedconnectivity, e.target.value]);
    } else {
      setselectedconnectivity(selectedconnectivity.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/connectivity', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setconnectivityList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [hotSwappableList, setahotSwappableList] = useState([]);
  const [selectedhotSwappable, setselectedhotSwappable] = useState([]);
  const handlehotSwappableCheckbox = (e) => {
    if (e.target.checked) {
      setselectedhotSwappable([...selectedhotSwappable, e.target.value]);
    } else {
      setselectedhotSwappable(selectedhotSwappable.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/hotSwappable', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setahotSwappableList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [lightingList, setlightingList] = useState([]);
  const [selectedlighting, setselectedlighting] = useState([]);
  const handlelightingCheckbox = (e) => {
    if (e.target.checked) {
      setselectedlighting([...selectedlighting, e.target.value]);
    } else {
      setselectedlighting(selectedlighting.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/lighting', {
      params: {
        orderBy: 'nameAsc'
      }
    })
    .then(function (response) {
      setlightingList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [knobList, setknobList] = useState([]);
  const [selectedknob, setselectedknob] = useState([]);
  const handleknobCheckbox = (e) => {
    if (e.target.checked) {
      setselectedknob([...selectedknob, e.target.value]);
    } else {
      setselectedknob(selectedknob.filter((item) => item !== e.target.value));
    }

  };
  useEffect(() => {
    axios.get(serverUrl+params.product+'/knob', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setknobList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);

  const [colorList, setcolorList] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const handleColorsCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedColors([...selectedColors, e.target.value]);
    } else {
      setSelectedColors(selectedColors.filter((item) => item !== e.target.value));
    }

    console.log(selectedColors)

  };

  useEffect(() => {
    axios.get(serverUrl+params.product+'/colors', {
      params: {
        orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setcolorList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.product]);
  
  const [loadedFurtherItems, setLoadedFurtherItems] = useState(true);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);
  const [lastScrollingOffset, setLastScrollingOffset] = useState(0);
  const [productList, setProductList] = useState([]);

  function loadProducts(currentScrollingOffset = 0) {
    if(currentScrollingOffset == 0){
      setLoaded(false);

      setLastScrollingOffset(0);
      setAllProductsLoaded(false);
      setLoadedFurtherItems(true);
    } else {
      setLoadedFurtherItems(false);
    }

    let paramsValues = {};
    if(params.product === 'switches'){
      paramsValues = {
          availability: selecteavailability.join(','),
          minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
          maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
          vendor: selectedManufacturers.join(','),
          stemType: selectedStems.join(','),
          switchType: selectedTypes.join(','),
          soundRange: sound,
          minActivationForce: activation.replace('1', '0').replace('2', '55').replace('3', '75').replace('4', '95'),
          maxActivationForce: activation.replace('1', '55').replace('2', '75').replace('3', '95').replace('4', '999'),
          soundType: selectedSoundTypes.join(','),
          color: selectedColors.join(','),
          orderBy: 'priceAsc',
          limit: 25,
          offset: currentScrollingOffset
        };
      } else if(params.product === 'key-caps'){
        paramsValues = {
          availability: selecteavailability.join(','),
          minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
          maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
          minAmountIncluded: range1Amount,
          maxAmountIncluded: range2Amount,
          vendor: selectedManufacturers.join(','),
          stemType: selectedStems.join(','),
          materials: selectedMaterials.join(','),
          keycapType: selectedkeycapTypes.join(','),
          keycapProfile: selectedkeycapProfile.join(','),
          includesNumpad: selectedincludesNumpad.join(','),
          color: selectedColors.join(','),
          orderBy: 'priceAsc',
          limit: 25,
          offset: currentScrollingOffset
        };
    } else if(params.product === 'tools'){
      paramsValues = {
        availability: selecteavailability.join(','),
        minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
        maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
        vendor: selectedManufacturers.join(','),
        color: selectedColors.join(','),
        orderBy: 'priceAsc',
        limit: 25,
        offset: currentScrollingOffset
      };
  } else if(params.product === 'lubes'){
    paramsValues = {
      availability: selecteavailability.join(','),
      minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
      maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
      vendor: selectedManufacturers.join(','),
      orderBy: 'priceAsc',
      limit: 25,
      offset: currentScrollingOffset
    };
  } else if(params.product === 'stabilizers'){
    paramsValues = {
      availability: selecteavailability.join(','),
      minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
      maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
      vendor: selectedManufacturers.join(','),
      materials: selectedMaterials.join(','),
      color: selectedColors.join(','),
      orderBy: 'priceAsc',
      limit: 25,
      offset: currentScrollingOffset
    };
  }else if(params.product === 'keyboard-barebones'){
    paramsValues = {
        availability: selecteavailability.join(','),
        minPrice: range1 > range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
        maxPrice: range1 <= range2 ? parseFloat(range2).toFixed(2) : parseFloat(range1).toFixed(2),
        vendor: selectedManufacturers.join(','),
        layout: selectedlayout.join(','),
        connectivity: selectedconnectivity.join(','),
        hotSwappable: selectedhotSwappable.join(','),
        lighting: selectedlighting.join(','),
        knob: selectedknob.join(','),
        color: selectedColors.join(','),
        orderBy: 'priceAsc',
        limit: 25,
        offset: currentScrollingOffset
      };
    }

    axios.get(serverUrl+params.product, {
      params: paramsValues
    })
    .then(function (response) {
      // If the offset is not 0, then we are scrolling down and we need to add the new products to the list else its a new search and we need to replace the list
      if(currentScrollingOffset == 0){
        setProductList(response.data);
        setLoaded(true);
      } else {
        if(response.data.length < 1){
          setAllProductsLoaded(true);
        }

        setProductList(productList.concat(response.data));
        setLastScrollingOffset(currentScrollingOffset);
        setLoadedFurtherItems(true);
      }
    })
    .catch(function (error) {
      console.log(error);
    });

    /*setProductList( [
      {
        id: "1",
        image: "/images/compCat/1 (1).png",
        name: "Keychron Q6 QMK Carbon Black Aluminium RGB Gateron",
        price: "45",
        sponsored: "sponsored",
        inStock: "1",
        tag1: "Loud",
        tag2: "Klicks",
        tag3: "Hot Swappable",
      },
      {
        id: "2",
        image: "/images/compCat/1 (2).png",
        name: "Keychron Q6 QMK Carbon Black Aluminium RGB Gateron",
        price: "45",
        sponsored: "sponsored",
        inStock: "1",
        tag1: "Loud",
        tag2: "Klicks",
        tag3: "Hot Swappable",
      }
    ]);*/
  }

  useEffect(() => {
    loadProducts(0);
  }, [range1, range2, selectedManufacturers, selectedStems, selectedTypes, sound, activation, selectedSoundTypes, selectedColors, range1Amount, range2Amount, selectedMaterials, selectedincludesNumpad, selectedkeycapTypes, selectedkeycapProfile, selecteavailability, selectedlayout, selectedconnectivity, selectedhotSwappable, selectedlighting, selectedknob])


  window.onscroll = function(ev) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight -(document.body.offsetHeight / 3)) {
        // you're at the bottom of the page
        if(loaded && loadedFurtherItems && !allProductsLoaded){
          loadProducts(lastScrollingOffset + 25);
        }
    }
  };
  

  return (
    <>
      <Header />
      <main>
        <div className="switchList">
          <div className="auto__container">
            <div className="switchList__inner">
              <div className="switchList__inner-title">
                <h2 className="sm">Select {params.product.replace('-', ' ')}</h2>
                <div className="breadcrumb">
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/products/"+params.product}>Parts</NavLink>
                    </li>
                    <li><NavLink to={"/products/"+params.product}>{params.product.replace('-', ' ').replace(/^./, params.product[0].toUpperCase())}</NavLink></li>
                  </ul>
                </div>
                <h6 className="uniq">{productList.length}{allProductsLoaded == 0 && productList.length >= 25 ? <>+</> : <></>} compatible {productList.length == 1 ? <> part</> : <> parts</>}
                
                </h6>
              </div>
              <button
                className="button filter"
                onClick={() => {
                  setFilter(!filter);
                }}
              >
                {filter ? <>{closeIcon} Hide</> : <>{filterIcon} Filters</>}
              </button>
              <div className="switchList__body">
                <div
                  className={"switchList__sidebar " + (filter ? "active" : "")}
                >
                  <div className="switchList__sidebar-inner">
                  {(true) && (
                      <div className="input">
                      <h5 className="big">Availability</h5>
                      <div className="input__checkbox">
                        {availabilityList.map((item, index) => {
                            return (
                              <div className="input__checkbox-item">
                                <input onChange={handleAvailabilityCheckbox} value={item.availability} type="checkbox" />
                                <label htmlFor=""></label>
                                <span>{item.availability}</span>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  )}

                  {(params.product === "switches") && (
                      <div className="input">
                      <h5 className="big">Type</h5>
                      <div className="input__checkbox">
                        {typeList.map((item, index) => {
                            return (
                              <div className="input__checkbox-item">
                                <input onChange={handleTypeCheckbox} value={item.switchType} type="checkbox" />
                                <label htmlFor=""></label>
                                <span>{item.switchType}</span>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  )}

                  {(params.product === "key-caps") && (
                    <div className="input">
                      <h5 className="big">Amount included</h5>
                      <div className="input__price">
                        <div className="input__price-row">
                          <span>Min amount</span>
                          <span>Max amount</span>
                        </div>
                        <div className="input__price-row">
                          <input value={range1Amount} onChange={(e) => {setRange1Amount(e.target.value)}}
                          />
                          <input
                            value={range2Amount} onChange={(e) => {setRange2Amount(e.target.value)}}
                          />
                        </div>
                      </div>
                      <div className="rangeSlider__price">
                        Amount:&nbsp;
                        <span>
                          {parseFloat(range1Amount) > parseFloat(range2Amount) ? parseFloat(range2Amount || 0) : parseFloat(range1Amount || 0)} Keycaps
                          &nbsp;-&nbsp;
                          {parseFloat(range1Amount) <= parseFloat(range2Amount) ? parseFloat(range2Amount || 0) : parseFloat(range1Amount || 0)} Keycaps
                        </span>
                      </div>
                    </div>
                  )}

                    <div className="input">
                      <h5 className="big">price range</h5>
                      <RangeSlider
                        {...{ range1, range2, setRange1, setRange2 }}
                      />
                      <div className="input__price">
                        <div className="input__price-row">
                          <span>Min price</span>
                          <span>Max price</span>
                        </div>
                        <div className="input__price-row">
                          <RangeSliderInput
                            value={range1}
                            setValue={setRange1}
                            validataionFunc={priceInpVal}
                            checkErrorFunc={(value) => value >= range2}
                          />
                          <RangeSliderInput
                            value={range2}
                            setValue={setRange2}
                            validataionFunc={priceInpVal}
                            checkErrorFunc={(value) => value <= range1}
                          />
                        </div>
                      </div>
                      <div className="rangeSlider__price">
                        Price:{" "}
                        <span>
                          ${range1 > range2 ? parseFloat(range2 || 0).toFixed(2) : parseFloat(range1 || 0).toFixed(2)} - $
                          {range1 <= range2 ? parseFloat(range2 || 0).toFixed(2) : parseFloat(range1 || 0).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="input searchInp">
                      <h5 className="big">manufacturer</h5>
                      <div className="search">
                        <input type="text" onChange={(e) => {setManufacturerSearchTerm(e.target.value)}} placeholder="Search" />
                        {search}
                      </div>
                      <div className="input__checkbox">

                      {manufacturerList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleManufacturerCheckbox} value={item.vendor} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.vendor}</span>
                            </div>
                          );
                      })}




                      </div>
                    </div>
                    {(params.product == "switches" || params.product == "key-caps") && (
                    <div className="input">
                      <h5 className="big">Stem</h5>
                      <div className="input__checkbox">
                        {stemList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleStemCheckbox} value={item.stemType} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.stemType}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "key-caps" || params.product == "stabilizers") && (
                    <div className="input">
                      <h5 className="big">Material</h5>
                      <div className="input__checkbox">
                        {materialList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleMaterialCheckbox} value={item.material} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.material}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "keyboard-barebones") && (
                    <div className="input">
                      <h5 className="big">Layout</h5>
                      <div className="input__checkbox">
                        {layoutList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handlelayoutCheckbox} value={item.layout} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.layout}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "keyboard-barebones") && (
                    <div className="input">
                      <h5 className="big">Connectivity</h5>
                      <div className="input__checkbox">
                        {connectivityList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleconnectivityCheckbox} value={item.connectivity} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.connectivity}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "keyboard-barebones") && (
                    <div className="input">
                      <h5 className="big">Hot Swappable</h5>
                      <div className="input__checkbox">
                        {hotSwappableList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handlehotSwappableCheckbox} value={item.hotSwappable} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.hotSwappable}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "keyboard-barebones") && (
                    <div className="input">
                      <h5 className="big">Lighting</h5>
                      <div className="input__checkbox">
                        {lightingList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handlelightingCheckbox} value={item.lighting} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.lighting}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "keyboard-barebones") && (
                    <div className="input">
                      <h5 className="big">Knob</h5>
                      <div className="input__checkbox">
                        {knobList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleknobCheckbox} value={item.knob} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.knob}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "key-caps") && (
                    <div className="input">
                      <h5 className="big">Keycap Type</h5>
                      <div className="input__checkbox">
                        {keycapTypeList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handlekeycapTypeCheckbox} value={item.keycapType} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.keycapType}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "key-caps") && (
                    <div className="input">
                      <h5 className="big">Keycap Profile</h5>
                      <div className="input__checkbox">
                        {keycapProfileList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handlekeycapProfileCheckbox} value={item.keycapProfile} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.keycapProfile}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "key-caps") && (
                    <div className="input">
                      <h5 className="big">Includes Numpad</h5>
                      <div className="input__checkbox">
                        {includesNumpadList.map((item, index) => {
                          return (
                            <div className="input__checkbox-item">
                              <input onChange={handleincludesNumpadCheckbox} value={item.includesNumpad} type="checkbox" />
                              <label htmlFor=""></label>
                              <span>{item.includesNumpad}</span>
                            </div>
                          );
                      })}
                      </div>
                    </div>
                    )}

                    {(params.product == "switches") && (
                      <div className="input">
                      <h5 className="big">Sound Range</h5>
                      <div className="rangeSlider sound">
                        <div className="rangeSlider__slider">
                          <input
                            ref={soundInp}
                            defaultValue="0"
                            onChange={(e) => {
                              setSound(e.target.value);
                            }}
                            type="range"
                            min="0"
                            max="2"
                          />
                          <span></span>
                          <span></span>
                        </div>
                        <div className="rangeSlider__slider-items">
                          <div
                            className={
                              "rangeSlider__slider-item " +
                              (sound === "0" ? "active" : "")
                            }
                            onClick={() => {
                              setSound("0");
                              soundInp.current.value = "0";
                            }}
                          >
                            Quiet
                          </div>
                          <div
                            className={
                              "rangeSlider__slider-item " +
                              (sound === "1" ? "active" : "")
                            }
                            onClick={() => {
                              setSound("1");
                              soundInp.current.value = "1";
                            }}
                          >
                            Moderate
                          </div>
                          <div
                            className={
                              "rangeSlider__slider-item " +
                              (sound === "2" ? "active" : "")
                            }
                            onClick={() => {
                              setSound("2");
                              soundInp.current.value = "2";
                            }}
                          >
                            Loud
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                    }

                    {(params.product == "switches") && (
                                        <div className="input">
                                        <h5 className="big">Sound Type</h5>
                                        <div className="input__checkbox">
                                          {soundTypeList.map((item, index) => {
                                                return (
                                                  <div className="input__checkbox-item">
                                                    <input onChange={handleSoundTypeCheckbox} value={item.soundType} type="checkbox" />
                                                    <label htmlFor=""></label>
                                                    <span>{item.soundType}</span>
                                                  </div>
                                                );
                                            })}
                                        </div>
                                      </div>
                    )}

                    {(params.product == "switches") && (
                                          <div className="input">
                                          <h5 className="big">Activation Force Range</h5>
                                          <div className="rangeSlider activation">
                                            <div className="rangeSlider__slider">
                                              <input
                                                ref={activationInp}
                                                defaultValue="1"
                                                onChange={(e) => {
                                                  setActivation(e.target.value);
                                                }}
                                                type="range"
                                                min="1"
                                                max="4"
                                              />
                                              <span></span>
                                              <span></span>
                                              <span></span>
                                            </div>
                                            <div className="rangeSlider__slider-items">
                                              <div
                                                className={
                                                  "rangeSlider__slider-item " +
                                                  (activation === "1" ? "active" : "")
                                                }
                                                onClick={() => {
                                                  setActivation("1");
                                                  activationInp.current.value = "1";
                                                }}
                                              >
                                                Light
                                              </div>
                                              <div
                                                className={
                                                  "rangeSlider__slider-item " +
                                                  (activation === "2" ? "active" : "")
                                                }
                                                onClick={() => {
                                                  setActivation("2");
                                                  activationInp.current.value = "2";
                                                }}
                                              >
                                                Medium
                                              </div>
                                              <div
                                                className={
                                                  "rangeSlider__slider-item " +
                                                  (activation === "3" ? "active" : "")
                                                }
                                                onClick={() => {
                                                  setActivation("3");
                                                  activationInp.current.value = "3";
                                                }}
                                              >
                                                Heavy
                                              </div>
                                              <div
                                                className={
                                                  "rangeSlider__slider-item " +
                                                  (activation === "4" ? "active" : "")
                                                }
                                                onClick={() => {
                                                  setActivation("4");
                                                  activationInp.current.value = "4";
                                                }}
                                              >
                                                Super heavy
                                              </div>
                                            </div>
                                            <div className="rangeSlider__slider-items">
                                              <div className="rangeSlider__slider-item">
                                                0-55g
                                              </div>
                                              <div className="rangeSlider__slider-item">
                                                55-75g
                                              </div>
                                              <div className="rangeSlider__slider-item">
                                                75-95g
                                              </div>
                                              <div className="rangeSlider__slider-item">95+g</div>
                                            </div>
                                          </div>
                                        </div>
                    )}

                    {(params.product == "key-caps" || params.product == "keyboard-barebones" || params.product == "stabilizers") && (
                                    <div className="input">
                                      <h5 className="big">Color</h5>
                                      <div className="input__colors">
                                      {colorList.map((item, index) => {
                                                return (
                                                <div className="input__colors-item">
                                                  <div className={"input__colors-item-color " + item.colorName.replace(/ /g, '').toLowerCase()}>
                                                    <input onChange={handleColorsCheckbox} value={item.colorName.replace(/ /g, '')} type="checkbox" />
                                                    <label htmlFor=""></label>
                                                  </div>
                                                  {item.colorName.replace(/ /g, '').replace(/-AND-/g, ' & ')}
                                                </div>
                                                );
                                            })}

                                        {/*
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color orange">
                                            <input onChange={handleColorsCheckbox} value="Orange" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Orange
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color red">
                                            <input onChange={handleColorsCheckbox} value="Red" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Red
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color blue">
                                            <input onChange={handleColorsCheckbox} value="Blue" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Blue
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color green">
                                            <input onChange={handleColorsCheckbox} value="Green" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Green
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color black">
                                            <input onChange={handleColorsCheckbox} value="Black" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Black
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color yellow">
                                            <input onChange={handleColorsCheckbox} value="Yellow" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Yellow
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color purple">
                                            <input onChange={handleColorsCheckbox} value="Purple" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Purple
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color pink">
                                            <input onChange={handleColorsCheckbox} value="Pink" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Pink
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color white">
                                            <input onChange={handleColorsCheckbox} value="White" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          White
                                        </div>
                                        <div className="input__colors-item">
                                          <div className="input__colors-item-color cyan">
                                            <input onChange={handleColorsCheckbox} value="Cyan" type="checkbox" />
                                            <label htmlFor=""></label>
                                          </div>
                                          Cyan
                                        </div>*/}


                                      </div>
                                    </div>
                    )}
                    <button
                      className="button filter"
                      onClick={() => {
                        setFilter(!filter);
                      }}
                    >
                      {filter ? (
                        <>{closeIcon} Hide</>
                      ) : (
                        <>{filterIcon} Filters</>
                      )}
                    </button>
                  </div>
                </div>
                <div className={"switchList__main " + (filter ? "" : "active")}>
                    {
                      // Check if page is loading
                      !loaded && (
                      <div style={{marginTop: '5rem', justifyContent: 'center'}} className="switchList__items">
                        <div className="switchList__items-item ">
                          <div className="switchList__items-item-text">
                            <h5>Loading...</h5>
                            <p>
                            We are currently loading the products for you...
                            </p>
                          </div>
                        </div>
                        </div>
                      ) 
                    }
                    {
                      // Check if no product are found
                      productList.length === 0 && loaded && (
                      <div style={{marginTop: '5rem', justifyContent: 'center'}} className="switchList__items">
                        <div className="switchList__items-item ">
                          <div className="switchList__items-item-text">
                            <h5>No Products found</h5>
                            <p>
                              We couldn't find any products that match your search
                              criteria.
                            </p>
                          </div>
                        </div>
                        </div>
                      ) 
                    }
                    {
                    // Show products if page is loaded
                    loaded && (
                      <div className="switchList__items">
                        {productList.map((item, index) => {
                          item['uiDisplayType'] = 'switchList';
                          return <SwitchListItem key={index} {...item} />;
                        })}
                      </div>
                      )
                    }
                    {
                    // Show products if page is loaded
                    !loadedFurtherItems && (
                      <h5 style={{marginBottom: '5rem'}}>Loading further Products...</h5>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
