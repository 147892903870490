import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { plus, setting, tickFilled, tickX, trash } from "../../Base/SVG";
import axios from "axios";
import { serverUrl } from "../../../consts.js";

export default function KeyboardBuilderItem(props) {
  let navigate = useNavigate(); 

  let buildHash = localStorage.getItem("buildHash");

  let cachedBuilddata = localStorage.getItem("buildData_" + buildHash);
  if(cachedBuilddata == "" || cachedBuilddata == null) {
    cachedBuilddata = {};
  } else{
    cachedBuilddata = JSON.parse(decodeURIComponent(cachedBuilddata));
  }

  let qnt = cachedBuilddata[props.type.replace('keyboard-', '').replace('key-caps', 'keycaps')+"QNT"];

  useEffect(() => {

    try {
      // Set the quantity input to the value from the cache
      document.getElementById("qntInput" + props.type).value = qnt;
    } catch (error) {
      
    }

  }, []);

  function openProducts(productsSite) {

    // Check if the buildhash is still loading
    if(localStorage.getItem("hashLoading") == "true") {
      // Wait 100ms and try again
      setTimeout(() => {
        openProducts(productsSite);
      }, 100);
      return;
    }

    navigate("/products/" + productsSite);
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function changeQntInput(amount, id){

    if(cachedBuilddata == "" || cachedBuilddata == null || cachedBuilddata == undefined || Object.keys(cachedBuilddata).length == 0) {
      console.log("cachedBuilddata is null, skip changing quantity");
      return;
    }

    // Check if the buildhash is still loading
    if(localStorage.getItem("hashLoading") == "true") {
      // Wait 100ms and try again
      setTimeout(() => {
        changeQntInput(amount, id);
      }, 100);
      return;
    }

    let input = document.getElementById(id);

    if(input != undefined) {
      if(input.value == "" || input.value == null) {
        input.value = 1;
      }
      let newValue = parseInt(input.value) + amount;

      if(newValue < 1) {
        newValue = 1;
      }

      input.value = newValue;
      updateQnt(id);
    }

  }

  function updateQnt(id) {
    let input = document.getElementById(id);

    // Don't update yet if the input is empty or null (let the user type in whatever they want)
    if(input.value == "" || input.value == null) {
      return;
    }

    // Once the user has finished typing, make sure the value is between 1 and 9999
    if(input.value < 1) {
      input.value = 1;
    } else if(input.value > 9999) {
      input.value = 9999;
    }

    // Check if the value has changed and if so, update the data
    if(input.value != qnt) {
      qnt = input.value;

      // Create a deep copy of the cached build data
      let newCachedBuildData = JSON.parse(JSON.stringify(cachedBuilddata));
      newCachedBuildData[props.type.replace('keyboard-', '').replace('key-caps', 'keycaps')+"QNT"] = Number(qnt);


      // Update the build data in the database
      // If no build hash is provided, get it from the cookies
      if(buildHash == '') {
        buildHash = localStorage.getItem("buildHash");
      }

      if(buildHash === "") {
        buildHash = " ";
      }

      let paramsValues = {
        barebonesProductID: newCachedBuildData['barebonesProductID'],
        switchesProductID: newCachedBuildData['switchesProductID'],
        keycapsProductID: newCachedBuildData['keycapsProductID'],
        stabilizersProductID: newCachedBuildData['stabilizersProductID'],
        lubesProductID: newCachedBuildData['lubesProductID'],
        toolsProductID: newCachedBuildData['toolsProductID'],
        barebonesQNT: newCachedBuildData['barebonesQNT'],
        switchesQNT: newCachedBuildData['switchesQNT'],
        keycapsQNT: newCachedBuildData['keycapsQNT'],
        stabilizersQNT: newCachedBuildData['stabilizersQNT'],
        lubesQNT: newCachedBuildData['lubesQNT'],
        toolsQNT: newCachedBuildData['toolsQNT'],
      };

      props.updateBuildDataFunction(newCachedBuildData);

      localStorage.setItem("hashLoading", "true");
      axios.get(serverUrl+'build/create', {
        params: paramsValues
      })
      .then(function (response) {
        // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
        let buildHistory = localStorage.getItem("buildHistory");
        if(buildHistory == "" || buildHistory == null) {
          buildHistory = {};
        } else {
          buildHistory = JSON.parse(decodeURIComponent(buildHistory));
        }

        buildHistory[new Date()] = ["Changed quantity of "+props.name, response.data['newBuildHash']];

        // Only keep the last 20 build history items
        buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

        // Set the build history cookie
        localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

        //navigate('../../build/'+response.data['newBuildHash']);
        window.history.replaceState(null, null, '../../build/'+response.data['newBuildHash']);
        props.updateBuildHashFunction(response.data['newBuildHash']);

        localStorage.setItem("hashLoading", "false");
      })
      .catch(function (error) {
        console.log(error);
      });

    }

  }

  function removeComponent(partId, partType, partName, buildHash = '') {
    // If no build hash is provided, get it from the cookies
    if(buildHash == '') {
      buildHash = localStorage.getItem("buildHash");
    }

    if(buildHash === "") {
      buildHash = " ";
    }

    /*axios.get(serverUrl+'build/'+buildHash+'/remove', {
      params: {
        partID: partId,
        partType: partType
      }
    })
    .then(function (response) {
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == null) {
        buildHistory = {};
      }else{
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      buildHistory[new Date()] = ["Removed "+partName+" from build", response.data['newBuildHash']];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

      navigate('../../build/'+response.data['newBuildHash']);


    })
    .catch(function (error) {
      console.log(error);

    });

    */

    // Create a new build with all parts except the one that was removed
    let cachedBuilddata = localStorage.getItem("buildData_" + buildHash);
    if(cachedBuilddata == "" || cachedBuilddata == null) {
      cachedBuilddata = {};
    } else {
      cachedBuilddata = JSON.parse(decodeURIComponent(cachedBuilddata));
    }

    let paramsValues = {
      barebonesProductID: cachedBuilddata['barebonesProductID'],
      switchesProductID: cachedBuilddata['switchesProductID'],
      keycapsProductID: cachedBuilddata['keycapsProductID'],
      stabilizersProductID: cachedBuilddata['stabilizersProductID'],
      lubesProductID: cachedBuilddata['lubesProductID'],
      toolsProductID: cachedBuilddata['toolsProductID'],
      barebonesQNT: cachedBuilddata['barebonesQNT'],
      switchesQNT: cachedBuilddata['switchesQNT'],
      keycapsQNT: cachedBuilddata['keycapsQNT'],
      stabilizersQNT: cachedBuilddata['stabilizersQNT'],
      lubesQNT: cachedBuilddata['lubesQNT'],
      toolsQNT: cachedBuilddata['toolsQNT'],
    };

    // Remove the part that was removed
    paramsValues[partType.replace('keyboard-barebones', 'barebones').replace('keyboard-switches', 'switches').replace('key-caps', 'keycaps')+'ProductID'] = undefined;

    // Update the cached build data
    cachedBuilddata[partType.replace('keyboard-barebones', 'barebones').replace('keyboard-switches', 'switches').replace('key-caps', 'keycaps')+'ProductID'] = null

    // Check if after removing the part, the build is empty
    let buildEmpty = true;
    for (const [key, value] of Object.entries(cachedBuilddata)) {
      // Check if the key is a productID
      if(key.includes('ProductID')) {
        // Check if the productID values are empty
        if(value != null && value != undefined && value != "") {
          buildEmpty = false;
        }
      }
    }

    // If the build is empty, create a new build
    if(buildEmpty) {
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == null) {
        buildHistory = {};
      } else {
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      localStorage.clear(); // Clear everything except the build history
      localStorage.setItem("buildHistory", buildHistory);
  
      localStorage.setItem("buildHash", " ", 365);

      buildHistory[new Date()] = ["Removed "+partName+" from build", " "];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

      navigate('../../build/ ');
      
      return;
    }

    props.updateBuildDataFunction(cachedBuilddata);

    localStorage.setItem("hashLoading", "true");
    axios.get(serverUrl+'build/create', {
      params: paramsValues
    })
    .then(function (response) {
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == null) {
        buildHistory = {};
      } else {
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      buildHistory[new Date()] = ["Removed "+partName+" from build", response.data['newBuildHash']];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);

      // Set the new build data
      localStorage.setItem("buildData_" + response.data['newBuildHash'], JSON.stringify(cachedBuilddata), 1);

      //navigate('../../build/'+response.data['newBuildHash']);
      window.history.replaceState(null, null, '../../build/'+response.data['newBuildHash']);
      props.updateBuildHashFunction(response.data['newBuildHash']);

      localStorage.setItem("hashLoading", "false");
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  return (
    <>
      {props.data === "0" ? (
        <tr>
          <td>{props.type.replace('ProductID', '').replace('-', ' ').replace(/^./, props.type[0].replace('-', ' ').toUpperCase())}</td>
          <td>
            <button onClick={() => openProducts(props.type.replace('ProductID', '').replace('barebones', 'keyboard-barebones').replace('keyboard-keyboard-barebones', 'keyboard-barebones').replace('keycaps', 'key-caps'))} className="button selectbtn">
              {plus} Select
            </button>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        <tr className="builder__table-row">
          <td className="builder__table-cat">
            {props.type.replace('ProductID', '').replace('-', ' ').replace(/^./, props.type[0].replace('-', ' ').toUpperCase())}
            <div className="builder__table-title">
              {trash}
              <Link to={props.urls.split(",")[0]} className="button buy">
                Buy
                </Link>
            </div>
          </td>
          <td className="builder__table-selected">
            <div className="builder__table-product">
              <div className="builder__table-product-inner">
                <div className="builder__table-product-image">
                  {props.images !== null ? (
                    <div>
                      <img
                      src={props.images.split(",")[0]}
                      alt={props.id}
                      loading="lazy"
                      />
                    </div>
                ) : (<div>
                  <p style={{fontSize: "12px", textAlign: 'center'}}>No Image found</p>
                </div>)}
                </div>
                <span><NavLink to={"/product/"+props.name.replace(/-/g, "-MINUS-").replace(/%/g, "-PERCENT-").replace(/\+/g, "-PLUS-").replace(/\//g, "-SLASH-").replace(/ /g, "-")}>{props.name}</NavLink></span>
              </div>
            </div>
            <div className="builder__table-selected-inner">
              <div className="builder__table-selected-col">
                <div className="builder__table-selected-title">Price</div>
                <div className="builder__table-selected-price">
                  {setting} {props.price}
                </div>
              </div>
              <div className="builder__table-selected-col">
                <div className="builder__table-selected-title">Group buy</div>
                {props.group === "1" ? (
                  <div className="builder__table-icon green">
                    {tickFilled}{" "}
                    <span>
                    A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
                    </span>
                  </div>
                ) : (
                  <div className="builder__table-icon" style={{color: "#ff3d00"}}>
                    {tickX}{" "}
                    <span>
                    A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
                    </span>
                  </div>
                )}
              </div>
              <div className="builder__table-selected-col">
                <div className="builder__table-selected-title">
                  Where to buy
                </div>
                <div className="builder__table-selected-image">
                  <img
                    src={props.logoURL}
                    alt={props.id}
                  />
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="builder__table-price">{"$ " + props.price}</div>
          </td>
          <td>
            {props.group === "1" ? (
              <div className="builder__table-icon green">
                {tickFilled}
                <span>
                A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
                </span>
              </div>
            ) : (
              <div className="builder__table-icon" style={{color: "#ff3d00"}}>
                {tickX}
                <span>
                A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
                </span>
              </div>
            )}
          </td>
          <td>
            <div class="builder__table-qnt">
              <button onClick={() => changeQntInput(-1, 'qntInput'+props.type)} class="button roundButton">-</button>
              <input onChange={() => updateQnt("qntInput"+props.type)} class="qntInput" id={"qntInput"+props.type} min={1} max={10000} step={1} type="number" placeholder="1" />
              <button onClick={() => changeQntInput(1, 'qntInput'+props.type)} class="button roundButton">+</button>
            </div>
          </td>
          <td>
            <div className="builder__table-buy">
              <a href={props.merchantURL} target='_blank' className="builder__table-buy-image">
                <img
                  src={props.logoURL}
                  alt={props.id}
                />
              </a>
              <a href={props.urls.split(",")[0]} target='_blank'><button className="button buy">Buy</button></a>
            </div>
          </td>
          <td class="removeComponentTrashcan" onClick={() => removeComponent(props.id, props.type, props.name)}>{trash}</td>
        </tr>
      )}
    </>
  );
}
