export const IntroModul = [
  {
    id: "1",
    image: "/images/sliderImage1.png",
  },
  /*{
    id: "2",
    image: "/images/sliderImage2.png",
  },
  /*{
    id: "3",
    image: "/images/sliderImage3.png",
  },
  {
    id: "4",
    image: "/images/compCat/1 (2).png",
  },*/
];
