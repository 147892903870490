export const CompCatModul = [
  {
    id: "1",
    image: "/images/compCat/1 (1).png",
    name: "keyboard barebones",
    link: "/products/keyboard-barebones",
  },
  {
    id: "2",
    image: "/images/compCat/1 (2).png",
    name: "switches",
    link: "/products/switches",
  },
  {
    id: "3",
    image: "/images/compCat/1 (3).png",
    name: "key caps",
    link: "/products/key-caps",
  },
  {
    id: "4",
    image: "/images/compCat/1 (4).png",
    name: "stabilizers",
    link: "/products/stabilizers",
  },
  {
    id: "5",
    image: "/images/compCat/1 (5).png",
    name: "lubes",
    link: "/products/lubes",
  },
  {
    id: "6",
    image: "/images/compCat/1 (6).png",
    name: "tools",
    link: "/products/tools",
  },
];
