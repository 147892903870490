import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Base/Header";

export default function SwitchListNotFound() {
  return (
    <>
      <Header />
      <main>
        <div className="switchList">
          <div className="auto__container">
            <div className="switchList__inner">
              <div className="switchList__inner-title">
                <h2 className="sm">select switch</h2>
                <div className="breadcrumb">
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/build">Parts</NavLink>
                    </li>
                    <li>Switches</li>
                  </ul>
                </div>
                <h6 className="uniq">85 compatible parts</h6>
              </div>
              <div className="switchList__notfound">
                <h5 className="big">No Parts found</h5>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
