import React, { useEffect, useState } from "react";

export default function RangeSlider({ range1, range2, setRange1, setRange2 }) {
  const min = 0;
  const max = 1000;

  const [firstInput, setFirstInput] = useState(0);
  const [secondInput, setSecondInput] = useState(1000);

  const updateRanges = ({ fInput = firstInput, sInput = secondInput }) => {
    let biggValue = fInput > sInput ? fInput : sInput;
    let smallValue = fInput <= sInput ? fInput : sInput;

    if (range1 !== smallValue) setRange1(smallValue);
    if (range2 !== biggValue) setRange2(biggValue);
  };

  useEffect(() => {
    let biggValue = range1 > range2 ? range1 : range2;
    let smallValue = range1 <= range2 ? range1 : range2;
    let biggInput = firstInput > secondInput ? "firstInput" : "secondInput";

    if (biggInput === "firstInput" && biggValue !== firstInput)
      setFirstInput(biggValue);
    if (biggInput === "secondInput" && biggValue !== secondInput)
      setSecondInput(biggValue);
    if (biggInput !== "firstInput" && smallValue !== firstInput)
      setFirstInput(smallValue);
    if (biggInput !== "secondInput" && smallValue !== secondInput)
      setSecondInput(secondInput);
    updateView();
  }, [range1, range2]);

  const updateView = (e) => {
    const rangeOne = document.querySelector('input[name="rangeOne"]');
    const rangeTwo = document.querySelector('input[name="rangeTwo"]');
    const outputOne = document.querySelector(".outputOne");
    const outputTwo = document.querySelector(".outputTwo");
    const inclRange = document.querySelector(".incl-range");

    outputOne.style.left = (parseFloat(range1) / max) * 100 + "%";
    outputTwo.style.left = (parseFloat(range2) / max) * 100 + "%";

    // if (parseFloat(rangeOne.value) > parseFloat(rangeTwo.value)) {
    //   inclRange.style.width =
    //     ((rangeOne.value - rangeTwo.value) / max) * 100 + "%";
    //   inclRange.style.left = (rangeTwo.value / max) * 100 + "%";
    // } else {
    inclRange.style.width = (parseFloat(range2 - range1) / max) * 100 + "%";
    inclRange.style.left = (parseFloat(range1) / max) * 100 + "%";
    // }
  };

  const updateRange1 = (e) => {
    let value = parseFloat(e.target.value);
    setFirstInput(value);
    updateRanges({
      fInput: value,
    });
  };
  const updateRange2 = (e) => {
    let value = parseFloat(e.target.value);
    setSecondInput(value);
    updateRanges({
      sInput: value,
    });
  };

  return (
    <div className="range-slider">
      <span className="outputOne"></span>
      <span className="outputTwo"></span>
      <span className="full-range"></span>
      <span className="incl-range"></span>
      <input
        name="rangeOne"
        min={min}
        max={max}
        step="1"
        type="range"
        value={parseFloat(firstInput)}
        onChange={updateRange1}
      />
      <input
        name="rangeTwo"
        min={min}
        max={max}
        step="1"
        value={parseFloat(secondInput)}
        type="range"
        onChange={updateRange2}
      />
    </div>
  );
}

export const RangeSliderInput = ({
  validataionFunc,
  checkErrorFunc,
  value,
  setValue,
  ...inputProps
}) => {
  const [innerValue, setinnerValue] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setinnerValue(value);
  }, [value]);

  const onChange = ({ target }) => {
    //let targetValue = !!parseFloat(target.value) ? parseFloat(target.value) : 0;
    let targetValue = target.value.replace(/[^0-9.,]/g, '');

    // Remove leading zeros if there are numbers after it but not a dot or comma
    if(targetValue[0] === '0' && targetValue[1] !== '.' && targetValue[1] !== ','){
      targetValue = targetValue.replace(/^0+/, '');
    }

    if(targetValue === ''){
      targetValue = 0;
    }

    if (!validataionFunc(targetValue)) return;
    setinnerValue(targetValue);

    if (checkErrorFunc(targetValue)) {
      setError(true);
    } else {
      setValue(targetValue);
      setError(false);
    }

  };


  return (
    <input
      {...inputProps}
      className={error ? "error" : ""}
      type="text"
      value={innerValue}
      onChange={onChange}
    />
  );
};
