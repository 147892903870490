import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../../Base/Footer";
import Header from "../../Base/Header";
import Build from "./Parts/Build/Build";
import Tabs from "./Parts/Tabs/Tabs";
import SwitchMainItem from "./SwitchMainItem";
import { SwitchMainModul } from "./SwitchMainModul";
import axios from "axios";
import { serverUrl } from "../../../consts.js";
import Zoom from 'react-img-zoom'

export default function SwitchMain() {
  const params = useParams();
  let navigate = useNavigate(); 

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: true,
  };
  const settings2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    centerMode: false,
    infinite: true,
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function addComponent(partId, partType, partName) {
    let buildHash = localStorage.getItem("buildHash");

    if(buildHash === "") {
      buildHash = " ";
    }

    axios.get(serverUrl+'build/'+buildHash+'/add', {
      params: {
        partID: partId,
        partType: partType
      }
    })
    .then(function (response) {
      // Get the build history from the cookies and add the new part to it OR create new build history if it doesn't exist
      let buildHistory = localStorage.getItem("buildHistory");
      if(buildHistory == "" || buildHistory == null) {
        buildHistory = {};
      }else{
        buildHistory = JSON.parse(decodeURIComponent(buildHistory));
      }

      buildHistory[new Date()] = ["Added "+partName+" to build", response.data['newBuildHash']];

      // Only keep the last 20 build history items
      buildHistory = Object.fromEntries(Object.entries(buildHistory).slice(-20));

      // Set the build history cookie
      localStorage.setItem("buildHistory", encodeURIComponent(JSON.stringify(buildHistory)), 365);
      
      navigate('../../build/'+response.data['newBuildHash']);
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }

  const [productData, setProductData] = useState([]);
  useEffect(() => {
    axios.get(serverUrl+'product/'+params.productName, {
      params: {
        //orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setProductData(response.data[0]);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.productName]);

  const [vendorUrls, setVendorUrls] = useState([]);
  useEffect(() => {
    axios.get(serverUrl+'product/'+params.productName+'/vendorUrls', {
      params: {
        //orderBy: 'nameDesc'
      }
    })
    .then(function (response) {
      setVendorUrls(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }, [params.productName]);

  useEffect(() => {
    if(nav1 !== undefined){
      nav1.slickGoTo(0);

    }

  }, [productData]);

  return (
    <>
      <Header />
      <main>
        <div className="switchMain">
          <div className="auto__container">
            <div className="switchMain__inner">
              <div className="switchMain__inner-title">
                <h2 className="sm">
                {(productData.name !== undefined) && (
                  "Select " + productData.type.replace('-', ' ')
                )}</h2>
                <div className="breadcrumb">
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/products/"+productData.type}>Parts</NavLink>
                    </li>
                    <li><NavLink to={"/products/"+productData.type}>{(productData.name !== undefined) && (productData.type.replace('-', ' ').replace(/^./, productData.type[0].toUpperCase()))}</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className="switchMain__inner-main">
                <div className="switchMain__sliders">
                  <div className="switchMain__slider1">
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1) => setNav1(slider1)}
                      {...settings}
                    >

                      {productData.images && productData.images.split(", ").map((item, index) => {
                        if(item !== ""){
                          return (
                            <div >
                              <Zoom
                              img={item.replace(",", "")}
                              zoomScale={2}
                              width={750}
                              height={500}
                            />
                            </div>
                          )
                          return;
                        }
                      })}
                    </Slider>
                  </div>
                  <div className="switchMain__slider2">
                    <Slider
                      asNavFor={nav1}
                      ref={(slider2) => setNav2(slider2)}
                      {...settings2}
                    >

                      {productData.images && productData.images.split(", ").map((item, index) => {
                        if(item !== ""){
                          return (
                            <div className="switchMain__slider2-item">
                            <div className="switchMain__slider2-item-image">
                              <img
                                src={
                                  item.replace(",", "")
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          )  
                        }
                      })}

                    </Slider>
                  </div>
                </div>
                <div className="switchMain__content-outer">
                  <div className="switchMain__content">
                    <h2 className="lh50">
                      {productData.name}
                    </h2>
                    <h6 className="uniq">
                      Manufacturer: <span>{productData.vendor}</span>
                    </h6>
                    {/*<div className="switchMain__content-rating">
                      <div className="rate">
                        <div className="rate__stars">
                          <img
                            src={process.env.PUBLIC_URL + "/images/stars.png"}
                            alt="stars"
                          />
                        </div>
                        <div className="rate__filled">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/stars-filled.png"
                            }
                            alt="stars"
                          />
                        </div>
                      </div>
                      <h6 className="fw400">(28 user ratings)</h6>
                    </div>*/}
                    <div className="switchMain__table">
                      <table>
                        <thead>
                          <tr>
                            <th>prices</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>Merchant</th>
                            <th>Base</th>
                            <th>Shipping</th>
                            <th>Availability</th>
                            <th>Group buy</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorUrls != [] && vendorUrls.map((item, index) => {
                            return <SwitchMainItem key={index} {...item} />;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <button onClick={() => addComponent(productData.id, productData.type, productData.name)} type="button" className="button primary large">
                    Add component
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<Build />*/}
        {(productData.name !== undefined) && (
          <Tabs {...productData} />
        )}
        </main>
      <Footer />
    </>
  );
}
