import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import { chevronBot } from "./SVG";

const LangList = [
  { id: "1", value: "EN ", image: "/images/flags/en.png" },
  { id: "2", value: "FR ", image: "/images/flags/fr.png" },
  { id: "3", value: "DE ", image: "/images/flags/de.png" },
  { id: "4", value: "IT ", image: "/images/flags/it.png" },
  { id: "5", value: "ES ", image: "/images/flags/es.png" },
];

export default function Header() {
  const wrapper = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [select, setSelect] = useState();
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const changeMedium = (item) => {
    setSelect(item.id);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  const start = 250;
  const onScroll = () => {
    if (window.scrollY > start) {
      document.getElementById("header").classList.add("sticky");
    } else {
      document.getElementById("header").classList.remove("sticky");
    }
  };
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setDropdown(false);
    };

    if (dropdown) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [dropdown]);
  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <div className="header__inner-side">
            <Link to="/" className="header__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
            </Link>
            <div
              className={"burger " + (menu ? "active" : "")}
              id="menuBtn"
              onClick={() => {
                setMenu(!menu);
              }}
            >
              <span></span>
            </div>
          </div>
          <nav className={"nav " + (menu ? "active" : "")} onClick={close}>
            <div className="nav__inner">
              <NavLink to="/build" className="nav__inner-link">
                Keyboard Builder
              </NavLink>
              {/*<NavLink to="/pre-build-keyboard" className="nav__inner-link">
                Pre-build keyboard
              </NavLink>*/}
              <div
                className={"nav__inner-dropdown " + (dropdown ? "active" : "")}
                ref={wrapper}
              >
                <div
                  className="nav__inner-dropdown-title"
                  onClick={() => {
                    setDropdown(!dropdown);
                  }}
                >
                  Browse Parts
                  {chevronBot}
                </div>
                <div className="nav__inner-dropdown-content">
                  <NavLink
                    to="/products/keyboard-barebones"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Barebones Keyboards
                  </NavLink>
                  <NavLink
                    to="/products/switches"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Switches
                  </NavLink>
                  <NavLink
                    to="/products/key-caps"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Key Caps
                  </NavLink>
                  <NavLink
                    to="/products/stabilizers"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Stabilizers
                  </NavLink>
                  <NavLink
                    to="/products/lubes"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Lubes
                  </NavLink>
                  <NavLink
                    to="/products/tools"
                    className="nav__inner-dropdown-link"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Tools
                  </NavLink>
                </div>
              </div>
            </div>
          </nav>
          {/*<CustomSelect
            list={LangList}
            selected={LangList[0]}
            onChange={changeMedium}
          />*/}
        </div>
      </div>
    </header>
  );
}
