//import NewPartsItem from "./NewPartsItem";
import SwitchListItem from "../../../SwitchList/SwitchListItem";
import { NewPartsModul } from "./NewPartsModul";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../../../consts.js";

export default function NewParts() {

  const [productList, setProductList] = useState([]);
  useEffect(() => {
      axios.get(serverUrl+'', {
        params: {
          orderBy: 'dateDesc',
          limit: 10
        }
      })
      .then(function (response) {
        setProductList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

      /*setProductList( [
        {
          id: "1",
          image: "/images/compCat/1 (1).png",
          name: "Keychron Q6 QMK Carbon Black Aluminium RGB Gateron",
          price: "45",
          sponsored: "sponsored",
          inStock: "1",
          tag1: "Loud",
          tag2: "Klicks",
          tag3: "Hot Swappable",
        },
        {
          id: "2",
          image: "/images/compCat/1 (2).png",
          name: "Keychron Q6 QMK Carbon Black Aluminium RGB Gateron",
          price: "45",
          sponsored: "sponsored",
          inStock: "1",
          tag1: "Loud",
          tag2: "Klicks",
          tag3: "Hot Swappable",
        }
      ]);*/
  }, [])

  return (
    <div className="newparts">
      <div className="auto__container">
        <div className="newparts__inner">
          <h2>New Parts</h2>
          <p className="sm">
            Check out the latest parts that have been added to the database
          </p>
          <div className="newparts__items">
            {
              // Check if no product are found
              productList.length === 0 && (
                <div className="switchList__items">
                  <p>Loading...</p>
                </div>
              ) } : {(
                <div className="switchList__items">
                  {productList.map((item, index) => {
                    item['uiDisplayType'] = 'newparts';
                    return <SwitchListItem key={index} {...item} />;
                  })}
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
