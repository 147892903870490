import React from "react";
import Footer from "../../Base/Footer";
import Header from "../../Base/Header";
import CompCat from "./Parts/CompCat/CompCat";
import HotBuilds from "./Parts/HotBuilds/HotBuilds";
import Intro from "./Parts/Intro/Intro";
import NewParts from "./Parts/NewParts/NewParts";
import WeekBuild from "./Parts/WeekBuild/WeekBuild";

export default function Index() {
  return (
    <>
      <Header />
      <main>
        <Intro />
        <CompCat />
        {/*<HotBuilds />*/}
        <NewParts />
        {/*<WeekBuild />*/}
      </main>
      <Footer />
    </>
  );
}
