import React from "react";
import Footer from "../../Base/Footer";
import Header from "../../Base/Header";

export default function Index() {
  return (
    <>
      <Header />
      <main>
        <div id="aboutUsDiv" className="auto__container">
          <div style={{marginRight: '5rem'}}>
            <h2>About Us</h2><br />
            <p style={{textAlign: 'left'}}>
            We created KeyboardPartPicker in the hopes of making the hobby of custom keyboard building more accessible by allowing you to customize your Mechanical Keyboard Build by filtering and selecting parts that our Tool automatically checks for compatibility.

            We also know that many sites selling keyboard parts have poor filter options, making it a time-consuming task to find your desired components.

            That is why, whether you are an experienced builder or a newbie, we try to make searching for individual parts as simple as possible by providing as many filters as possible.

            As a result, we are always looking for feedback that will assist us in improving the site or the experience.
            </p>
            <br />
            <p style={{textAlign: 'left'}}>Feel free to email us at <a href="mailto:contact@KeyboardPartPicker.io"> contact@KeyboardPartPicker.io</a>
            </p>
            <br />
            <p style={{textAlign: 'left'}}>Specifically, we would love feedback on
            <ul>
              <li style={{listStyle: 'disc'}}>Products shown on the site
              </li>
              <li style={{listStyle: 'disc'}}>Established vendors you would like seen on the site
              </li>
              <li style={{listStyle: 'disc'}}>Bugs or limitations with the site
              </li>
            </ul>
            </p>
          </div>
          <div>
            <img id="aboutUsImage" src={process.env.PUBLIC_URL + "/images/sliderImage1.png"}  alt="" />
          </div>
          <br /><br />
        </div>
      </main>
      <Footer />
    </>
  );
}
