import React from "react";

export default function CompCatItem(props) {
  return (
    <div className="compcat__inner-item">
      <a href={process.env.PUBLIC_URL + props.link} className="compcat__inner-item-image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </a>
      <h4>{props.name}</h4>
    </div>
  );
}
