import React from "react";
import { chevronRight, star } from "../../../../../Base/SVG";
import ReviewItem from "./ReviewItem";
import { ReviewModul } from "./ReviewModul";

export default function Review() {
  return (
    <div className="tabs__review">
      <div className="tabs__review-title">
        <h5 className="big fw500">verified users feedback</h5>
        <a href="#">
          <h6 className="fw400">see all </h6> {chevronRight}
        </a>
      </div>
      <div className="tabs__review-inner">
        <div className="tabs__review-rating">
          <h5 className="big fw500">User Ratings (28)</h5>
          <div className="tabs__review-rating-box">
            <div className="tabs__review-rating-total">
              <h3>4.5/5</h3>
              <div className="rate large">
                <div className="rate__stars">
                  <img
                    src={process.env.PUBLIC_URL + "/images/stars.png"}
                    alt="stars"
                  />
                </div>
                <div className="rate__filled star3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/stars-filled.png"}
                    alt="stars"
                  />
                </div>
              </div>
              <h6 className="fw400">(4.5 Average)</h6>
            </div>
            <div className="tabs__review-rating-info">
              <div className="tabs__review-rating-info-item">
                <div className="tabs__review-rating-info-item-inner">
                  <h4>5</h4> {star}
                  <h6 className="fw400">(15)</h6>
                </div>
                <div className="tabs__review-rating-info-item-progress">
                  <span style={{ width: "72%" }}></span>
                </div>
              </div>
              <div className="tabs__review-rating-info-item">
                <div className="tabs__review-rating-info-item-inner">
                  <h4>4</h4> {star}
                  <h6 className="fw400">(6)</h6>
                </div>
                <div className="tabs__review-rating-info-item-progress">
                  <span style={{ width: "22%" }}></span>
                </div>
              </div>
              <div className="tabs__review-rating-info-item">
                <div className="tabs__review-rating-info-item-inner">
                  <h4>3</h4> {star}
                  <h6 className="fw400">(5)</h6>
                </div>
                <div className="tabs__review-rating-info-item-progress">
                  <span style={{ width: "20%" }}></span>
                </div>
              </div>
              <div className="tabs__review-rating-info-item">
                <div className="tabs__review-rating-info-item-inner">
                  <h4>2</h4> {star}
                  <h6 className="fw400">(1)</h6>
                </div>
                <div className="tabs__review-rating-info-item-progress">
                  <span style={{ width: "8%" }}></span>
                </div>
              </div>
              <div className="tabs__review-rating-info-item">
                <div className="tabs__review-rating-info-item-inner">
                  <h4>1</h4> {star}
                  <h6 className="fw400">(1)</h6>
                </div>
                <div className="tabs__review-rating-info-item-progress">
                  <span style={{ width: "8%" }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs__review-comment">
          <h5 className="big fw500">comment from verified Users (10)</h5>
          <div className="tabs__review-comment-items">
            {ReviewModul.map((item, index) => {
              return <ReviewItem key={index} {...item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
