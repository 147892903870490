import React, { useEffect, useRef, useState } from "react";
import { facebook, linkedin, discord, reddit, telegram, twitter } from "./SVG";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../consts.js";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${serverUrl}newsletter/subscribe`, { 
          email: email,
          source: "footer"
       })
      .then((res) => {
        console.log(res);
        setSubmittedSuccessfully(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <footer className="footer">
      <div className="footer__row">
        <div className="auto__container">
        {
          submittedSuccessfully ? <> 
            <div className="footer__touch">
                    <h2 className="lh50">Thank you for subscribing !</h2>
                    <p className="sm">
                    We will email {email} once we have new releases, offers, promotions, or other interesting information, but we will of course not spam you ;)
                    </p>
                </div>
          </> : <>
                <div className="footer__touch">
                    <h2 className="lh50">Stay In Touch</h2>
                    <p className="sm">
                      Keep up to date on the latest releases, offers, and promotion from
                      Keyboard Part Picker
                    </p>
                    <div className="footer__touch-email">
                      <input onChange={(e) => {setEmail(e.target.value)}} type="text" placeholder="example@mail.com" />
                      <button onClick={handleSubmit} type="button" className="button notify">
                        Notify me
                      </button>
                    </div>
                </div>
            </>
          }

        </div>
      </div>
      <div className="footer__row">
        <div className="footer__info">
          <div className="auto__container">
            <div className="footer__info-inner">
              <div className="footer__info-social">
                <a
                  href="https://discord.gg/f9VxVBjMEM"
                  target="_blank"
                  className="footer__info-social-link"
                >
                  {discord}
                </a>
                <a
                  href="https://twitter.com/mechpartpicker"
                  target="_blank"
                  className="footer__info-social-link"
                >
                  {twitter}
                </a>
                <a
                  href="https://www.reddit.com/r/KeyboardPartPicker/"
                  target="_blank"
                  className="footer__info-social-link"
                >
                  {reddit}
                </a>
              </div>
              <div className="footer__info-links">
                <a href="/conditions" className="footer__info-link">
                Terms & Conditions 
                </a>
                <a href="/privacy" className="footer__info-link">
                  Privacy policy
                </a>
                <a href="/about" className="footer__info-link">
                  About Us
                </a>
                <a href="/contact" className="footer__info-link">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copy">
          <div className="auto__container">
            <div className="footer__copy-inner">
              Copyright © 2020-23 kepp KeyboardPartPicker | All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
