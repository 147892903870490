import React from "react";
import { tickFilled, tickX } from "../../Base/SVG";

export default function SwitchMainItem(props) {
  return (
    <tr>
      <td>
        <a href={props.merchantURL} target='_blank' className="switchMain__table-image">
          <img src={props.logoURL} alt={props.merchantName} />
        </a>
      </td>
      <td>{"$"+props.price}</td>
      <td>{props.shipping == 0 ? "+Free s/h" : "+ $"+props.shipping}</td>
      <td>{props.availability}</td>
      <td>
        {props.shipping === "Group Buy" ? (
          <div className="switchMain__table-icon green">
            {tickFilled}
            <span>
A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
            </span>
          </div>
        ) : (
          <div className="switchMain__table-icon" style={{color: "#ff3d00"}}>
            {tickX}
            <span>
            A Group Buy (or GB) is the Keyboard Community's term for crowd funding. Products in group buy must sell a certain number of units before manufacturing can begin. Production will begin once the purchase reaches its MOQ (minimum order quantity) and the group buy period officially ends. This means that if the target number is not reached before the group buy's deadline, the product will not be produced and participants will be refunded.
            </span>
          </div>
        )}
      </td>
      <td>{"$"+ (Number(props.price) + Number(props.shipping))}</td>
      <td>
        <a href={props.productURL} target='_blank'>
          <button className="button buy">Buy</button>
        </a>
      </td>
    </tr>
  );
}
