import React from "react";
import Footer from "../../Base/Footer";
import Header from "../../Base/Header";

export default function Index() {
  return (
    <>
      <Header />
      <main>
        <div id="aboutUsDiv" className="auto__container">
          <div style={{marginRight: '5rem'}}>
            <h2>Contact Us</h2><br />
            <p style={{textAlign: 'left'}}>
            You wanna talk or have Questions?

            Just write an E-Mail to the following Adress or join our Discord and you will get a response as fast as possible
            </p>
            <br />
            <p style={{textAlign: 'left'}}>Feel free to email us at <a href="mailto:contact@KeyboardPartPicker.io"> contact@KeyboardPartPicker.io</a>
            <br />
            or <a href="https://discord.com/invite/f9VxVBjMEM">join the Discord Server here</a>
            </p>
          </div>
          <div>
            <img id="aboutUsImage" src={process.env.PUBLIC_URL + "/images/sliderImage1.png"}  alt="" />
          </div>
          <br /><br />
        </div>
      </main>
      <Footer />
    </>
  );
}
